<template>
  <div>
    <v-app-bar
      dense
      dark
    >
      <v-toolbar-title>
        <div>
          {{ label}}:
          <strong
            v-if="$sessionStorage.zoneId !== null"
            style="cursor:pointer"
            @click="clickAreaNavBar"
          >{{ areaMapName }}</strong>
          <strong v-else>{{ elementSelected.type }} {{ elementSelected.id }} ({{ elementSelected.radius }} m)</strong>
          <strong
            v-if="nameStationSelected !== null"
            style="color:#F4C020"
          >>{{ nameStationSelected }} </strong>
        </div>
      </v-toolbar-title>

      <v-spacer />

      <v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer />
      <v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer />
      <v-spacer /><v-spacer /><v-spacer /><v-spacer /><v-spacer />
      <v-row justify="right">
        <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              class="mr-auto"
              v-on="on"
            >
              Time Machine
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Time Machine</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Picker without buttons"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          :error-messages="dateError"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="date"
                        @input="menu2 = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="11"
                    sm="5"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menuTime"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="time"
                          label="Picker in menu"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          :error-messages="timeError"
                          v-on="on"
                        />
                      </template>
                      <v-time-picker
                        v-if="menuTime"
                        v-model="time"
                        full-width
                        @click:minute="$refs.menu.save(time)"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                CANCEL
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!isFormValid"
                @click="setTimeMachine"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
              color="primary"
              dark
              v-bind="attrs"
              class="mr-auto"
              v-on="on"
              @click="goToLive"
            >
            <v-icon left>
              {{ iconSync }}
            </v-icon>
            </v-btn>
      </v-row>

      <div>
        <strong>
          {{ dateTimeText }}
        </strong>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mdiSync } from '@mdi/js'
export default {
  name: 'TIANavBar',

  components: {
  },

  data: () => ({
    iconSync: mdiSync,
    dateTimeText: null,
    dialog: false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu2: false,
    time: null,
    menuTime: false
  }),
  props: {
    label: {
      type: String,
      default: 'Area'
    },
  },
  computed: {
    ...mapState({
      areaMapName: ({ areaMap }) => areaMap.at(-1)?.NAME,
      nameStationSelected: (state) => state.TIA.TIAMap.nameStationSelected
    }),
    dateError () {
      return this.date ? [] : ['Date is required']
    },
    timeError () {
      return this.time ? [] : ['Time is required']
    },
    isFormValid () {
      return !this.dateError.length && !this.timeError.length
    }
  },

  watch: {

  },
  mounted () {
    const fechaHoraActual = new Date()
    const fecha = window.sessionStorage.getItem('dateMachine') !== null
      ? window.sessionStorage.getItem('dateMachine')
      : fechaHoraActual.toLocaleDateString()
    const hora = window.sessionStorage.getItem('timeMachine') !== null
      ? window.sessionStorage.getItem('timeMachine')
      : fechaHoraActual.toLocaleTimeString()
    this.dateTimeText = fecha + ' ' + hora
  },

  methods: {
    clickAreaNavBar () {
      this.$store.dispatch('setElement', { path: 'TIA.tabTIA', value: 0 })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMap.nameStationSelected', value: null })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.supplyPointSelectedId', value: null })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.supplyPointSelectedName', value: null })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.tabTIAMonitoring', value: 0 })
    },
    setTimeMachine () {
      window.sessionStorage.setItem('dateMachine', this.date)
      window.sessionStorage.setItem('timeMachine', this.time + ':00')
      this.$store.dispatch('setElement', { path: 'TIA.timeMachine', value: this.date })
      this.dialog = false
    },
    goToLive(){
      window.sessionStorage.removeItem('dateMachine');
      window.sessionStorage.removeItem('timeMachine');
      this.$store.dispatch('setElement', { path: 'TIA.timeMachine', value: null })
    }

  }
}
</script>

<style scoped>
::v-deep .v-list-item__content{
    text-align:left !important;
}
::v-deep .v-list-item__link{
    text-align:left !important;
}
</style>
