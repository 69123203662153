<template>
  <v-dialog
    v-model="showForm"
    style="z-index:501"
    max-width="450"
    overlay-opacity="0.4"
  >
    <v-card elevation="10">
      <v-form
        ref="HPForm"
        v-model="valid"
        lazy-validation
      >
        <v-card-title class="text-h5">
          {{ $t('new_connections.hp_conn_params') }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="values.ph"
                  :items="phItems"
                  :label="$t('new_connections.phase_connection')"
                  value="ABC"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="values.ndevs"
                  :rules="VALIDATIONS.ndevs"
                  :label="$t('dt.n_hps')"
                  type="number"
                  dense
                  @change="setToNumber('ndevs')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="values.nhP"
                  :rules="VALIDATIONS.nhP"
                  dense
                  :label="$t('dt.hp_nominal_power')"
                  type="number"
                  suffix="kVA"
                  @change="setToNumber('nhP')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="values.pf"
                  :rules="VALIDATIONS.pf"
                  :label="$t('new_connections.hp_power_factor')"
                  type="number"
                  dense
                  @change="setToNumber('pf')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="values.cop"
                  :rules="VALIDATIONS.cop"
                  :label="$t('dt.hp_cop')"
                  type="number"
                  suffix="COP"
                  dense
                  @change="setToNumber('cop')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#f4c020"
            text
            @click="closeForm"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            v-if="payload.edit"
            :disabled="!valid"
            color="#f4c020"
            text
            @click="editDevice"
          >
            {{ $t('edit') }}
          </v-btn>
          <v-btn
            v-if="payload.edit"
            :disabled="!valid"
            color="#f4c020"
            text
            @click="deleteDevice"
          >
            {{ $t('delete') }}
          </v-btn>
          <v-btn
            v-else
            :disabled="!valid"
            data-cy="dt-add-hp"
            color="#f4c020"
            text
            @click="addDevice"
          >
            {{ $t('add') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import digitalTwinForm from '@/mixins/digitalTwinForm'

export default {
  name: 'NewConnectionsHPForm',

  mixins: [digitalTwinForm],

  data () {
    return {
      DEVICE: 'HP',
      values: {
        ndevs: 1,
        nhP: 5,
        cop: 2,
        ph: 4,
        pf: 1
      }
    }
  }
}
</script>

<style scoped>
::v-deep .v-list-item__content{
    text-align:left !important;
}
</style>
