<template>
  <v-form
    ref="TopologyDataUploadForm"
    v-model="valid"
    lazy-validation
  >
    <v-row
      v-if="!sent"
      align="center"
      align-content="center"
      justify="center"
    >
      <v-col
        cols="12"
        self-align="center"
        style="margin:30px 10px 0px 10px"
        class="form-text"
      >
        You are about to load {{ fileTitle }} file
        <div style="font-size:12px">
          *Make sure it contains topology information
        </div>
      </v-col>
      <v-col
        cols="12"
        self-align="center"
      >
        <v-btn
          color="#f4c020"
          text
          style="margin:20px"
          @click="uploadTopologyFile"
        >
          Load File
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-else
      align="center"
      align-content="center"
      justify="center"
    >
      <v-col
        cols="12"
        self-align="center"
        style="margin:30px 10px 0px 10px"
        class="form-text"
      >
        <v-alert
          :type="responseType"
          :color="responseColor"
        >
          {{ responseMessage }}
        </v-alert>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import { postTopologyFile } from '@/services/dataImporter'

export default {
  name: 'TopologyDataUpload',
  props: {

    fileData: {
      type: File,
      default: null
    },
    fileTitle: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      valid: true,
      sent: false,
      responseType: null,
      responseColor: null,
      responseMessage: null
    }
  },
  methods: {
    uploadFile () {
      this.$emit('uploadFile')
    },

    async uploadTopologyFile () {
      const file = {
        data: this.fileData,
        title: this.fileTitle
      }
      let response
      try {
        response = await postTopologyFile(file)
      }
      catch(err) {
        console.log(err)
      }

      this.sent = true
      if (response.type === 'success') {
        this.responseType = 'success'
        this.responseColor = '#089611'
        this.responseMessage = `file ${this.fileTitle} has been sent successfully loaded: ${response.data.description}`
      } else if (response.type === 'warning'){
        this.responseType = 'warning'
        this.responseColor = '#D8810C'
        this.responseMessage = `file ${this.fileTitle} loaded with warnings: ${response.description}`
      } else {
        this.responseType = 'error'
        this.responseColor = '#A73636'
        this.responseMessage = `file ${this.fileTitle} could not be loaded: ${response.data.description}`
      }
    }
  }
}
</script>
<style scoped>
.form-text{
  color:#cccccc; font-family:'Faktum Light', sans-serif;
}
::v-deep .v-list-item__link{
    text-align:left !important;
}
::v-deep .v-list-item{
    text-align:left !important;
}
</style>
