import { plexiRequest } from '.'

export const getVersion = async () => {
  // return plexiRequest({ url: 'auth/version-info/' })
  // TODO: This is hardcoded. We still need the real call to the back
  return {
    gui: 'v24.4.0',
    aritatari: 'v24.4.0',
    tia: 'v24.4.0'
  }
}
