<template>
  <v-dialog
    v-model="showForm"
    max-width="450"
    overlay-opacity="0.6"
  >
    <v-card elevation="10">
      <v-card-title class="text-h5">
        <v-spacer />
        <div class="text-center title">
            {{ $t('dt.digital_twin_project') }}
        </div>
        <v-spacer />
     
      </v-card-title>
      <v-spacer />
      <v-card-text>
        <v-container>
          <v-row
            align="center"
            align-content="center"
            justify="center"
          >
            <v-col
              self-align="center"
            >
              <v-btn-toggle
                v-model="toggleTypeOfProject"
                dense
                rounded
                mandatory
              >
                <v-btn
                  data-cy="dt-new-project"
                  rounded
                  @click="hideFeedback"
                >
                  {{ $t('new') }}
                </v-btn>
                <v-btn
                  data-cy="dt-my-projects"
                  rounded
                  @click="hideFeedback"
                >
                  {{ $t('dt.my_projects') }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <ProjectFormOpen
            v-if="showOpenProject"
            :key-form="keyFormOpen"
            @deleteProject="deleteProject"
            @openProject="openProject"
          />
          <ProjectFormCreate
            v-if="showCreateProject"
            :network-list="networksListArray"
            @selectArea="selectStationsOnMap"
            @createNewProject="createNewProject"
            @createNewProjectPlanning="createNewProjectPlanning"
          />
          <slot name="feedback" />
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import ProjectFormOpen from '@/components/DigitalTwin/Forms/DTProjectFormOpen.vue'
import ProjectFormCreate from '@/components/DigitalTwin/Forms/DTProjectFormCreate.vue'

export default {
  name: 'DTProjectForm',
  components: {
    ProjectFormOpen,
    ProjectFormCreate
  },
  props: {
    showForm: {
      type: Boolean,
      default: true
    },
    networkList: {
      type: Array,
      default: null
    },
    formDefaultAction: {
      type: Number,
      default: 0

    },
    keyForm: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      toggleTypeOfProject: this.formDefaultAction,
      showCreateProject: true,
      showOpenProject: false,
      keyFormOpen: 0
    }
  },
  computed: {
    networksListArray () {
      const options = []
      this.networkList.forEach((network) => {
        options.push({ NAME: network.NAME, LEVEL: network.LEVEL })
      })
      return options
    }
  },
  watch: {
    keyForm () {
      this.keyFormOpen += 1
    },
    formDefaultAction () {
      this.toggleTypeOfProject = this.formDefaultAction
    },
    toggleTypeOfProject (typeOfProject) {
      if (typeOfProject === 0) {
        this.showOpenProject = false
        this.showCreateProject = true
      } else {
        this.showOpenProject = true
        this.showCreateProject = false
      }
    }
  },
  methods: {
    createNewProject (newProjectName, simNetworks, simParams, qualityParams) {
      this.$emit('createNewProject', newProjectName, simNetworks, simParams, qualityParams)
    },

    createNewProjectPlanning (newProjectName, simNetworks, simParams, qualityParams, config) {
      this.$emit('createNewProjectPlanning', newProjectName, simNetworks, simParams, qualityParams, config)
    },

    openProject (newconnections, projectName, details) {
      this.$emit('openProject', newconnections, projectName, details)
    },

    deleteProject (projectName) {
      this.$emit('deleteProject', projectName)
    },

    selectStationsOnMap () {
      this.$emit('selectStationsOnMap')
    },

    hideFeedback () {
      this.$emit('hideFeedback')
    }
  }
}
</script>

<style scoped>
.v-dialog__content {
  z-index: 501 !important;
}
.v-overlay{
  z-index:501 !important;
}
.v-btn.v-item--active{
  background-color: #f4c020 !important;
  color:#000 !important;
}
.v-card__title{
    text-align:center !important;
}
</style>
<style scoped>
::v-deep .v-card__title{
    text-align:center !important;
}
.title {
  text-align: center;
  font-size: 24px;
  color: #f4c020;
}
</style>
