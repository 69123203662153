<template>
  <div>
    <div v-if="showError">
      <CMError
        :text="textError"
        :code="numberError"
        @hideError="hideError"
      />
    </div>
    <div v-else>
      <v-form
        ref="openProjectForm"
        v-model="valid"
        style="margin-top:20px;"
        lazy-validation
      >
        <v-row>
          <v-col
            self-align="center"
            cols="12"
          >
            <v-btn-toggle
              v-model="toggleTypeOfProject"
              dense
              rounded
              mandatory
            >
              <v-btn>{{ $t('dt.new_connections') }}</v-btn>
              <v-btn>{{ $t('dt.planning') }}</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div v-if="showNewConnections">
              <v-row>
                <v-col
                  cols="12"
                  data-cy="dt-projects-list"
                >
                  <v-autocomplete
                    v-if="projectsList"
                    v-model="value"
                    :items="projectsListNewConnections"
                    :loading="loading"
                    item-text="name"
                    return-object
                    dense
                    allow-overflow
                    :label="$t('dt.project')"
                    :placeholder="$t('dt.select_project')"
                  />
                  <div v-else>
                    <CMLoader
                      :loading="loadingSelf.isVisible"
                      :message="$t('dt.loading_projects')"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-if="showPlanning">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="value"
                    :items="projectsListPlanning"
                    item-text="name"
                    return-object
                    dense
                    allow-overflow
                    :label="$t('dt.project')"
                    :placeholder="$t('dt.select_project')"
                  />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-card
          v-if="projectInfo && value !== null"
          style="margin-top:4px; margin-bottom:20px"
          dark
          elevation="5"
        >
          <v-card-text>
            <v-list
              dense
              rounded
            >
              <v-list-item
                v-if="showNewConnections"
                style="text-align:left; padding-left:0"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('start_date' ) }}</v-list-item-title>
                  <v-list-item-subtitle>{{ projectDateInit }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="showPlanning"
                style="text-align:left; padding-left:0"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('dt.simulation_date') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ projectDateInit }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="text-align:left; padding-left:0">
                <v-list-item-content>
                  <v-list-item-title>{{ $t('grid.stations') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ projectNetworks }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="showNewConnections"
                style="text-align:left; padding-left:0"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('dt.number_of_cases') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ projectCases }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="showPlanning"
                style="text-align:left; padding-left:0"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('type') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ projectPlannningType }} {{ $t('dt.penetration') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider />
            <p style="text-align:right; font-size:11px; margin:0 5px">
              {{ $t('dt.created_on') }} {{ projectDateCreation }}
            </p>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col
            cols="6"
            self-align="center"
          >
            <v-btn
              data-cy="dt-open-project"
              color="#f4c020"
              text
              @click="openProject()"
            >
              {{ $t('open') }}
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            self-align="center"
          >
            <v-btn
              color="#f4c020"
              data-cy="dt-delete-project"
              text
              @click="deleteProject()"
            >
              {{ $t('delete') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>

import DigitalTwinCreator from '@/assets/DigitalTwin/DigitalTwinCreator'
import CMLoader from '@/components/Common/CMLoader.vue'
import { mdiAlertCircle } from '@mdi/js'
import CMError from '@/components/Common/CMError.vue'
export default {
  name: 'OpenProjectForm',
  components: {
    CMLoader,
    CMError
  },
  props: {
    keyForm: {
      type: Number
    }
  },
  data () {
    return {
      valid: true,
      toggleTypeOfProject: 0,
      showNewConnections: true,
      showPlanning: false,
      panelOpenProjects: [0, 1],
      projectsList: null,
      value: [],
      dtc: {},
      showConfirmDelete: false,
      projectInfo: null,
      loadingSelf: {
        isVisible: true,
        message: 'Loading Projects'
      },
      loading: true,
      showError: false,
      numberError: null,
      textError: null,
      errorIcon: mdiAlertCircle
    }
  },
  computed: {
    projectsListPlanning () {
      const options = []
      this.projectsList.forEach((project) => {
        if (project.type === 1 || project.type === 2) {
          options.push({ name: project.name, id: project.id })
        }
      })
      return options
    },
    projectsListNewConnections () {
      const options = []
      this.projectsList.forEach((project) => {
        if (project.type === 0) {
          options.push({ name: project.name, id: project.id })
        }
      })
      return options
    },
    projectDateCreation () {
      if (this.projectInfo.date) {
        this.$store.dispatch('setElement', { path: 'DTProjectInfo.date', value: this.projectInfo.date.slice(0, 10) })
        return this.projectInfo.date.slice(0, 10)
      }
      this.$store.dispatch('setElement', { path: 'DTProjectInfo.date', value: '01/06/2022' })
      return '01/06/2022'
    },
    projectDateInit () {
      const dateData = new Date()
      dateData.setTime(this.projectInfo.init_date)
      const options = {
        year: 'numeric', month: 'long', day: 'numeric'
      }
      return dateData.toLocaleDateString('en-UK', options)
    },
    projectPlannningType () {
      let projectType
      Object.values(this.projectsList).forEach((proj) => {
        if (this.projectInfo.id === proj.id) {
          projectType = proj.type
        }
      })
      if (projectType === 1) {
        this.$store.dispatch('setElement', { path: 'planningType', value: 'EV' })
        return 'EV'
      }
      this.$store.dispatch('setElement', { path: 'planningType', value: 'PV' })
      return 'PV'
    },
    projectNetworks () {
      let networksString = ''
      for (const network in this.projectInfo.networks) {
        networksString = `${networksString} ${this.projectInfo.networks[network]} ,`
      }
      return networksString.slice(0, -1)
    },
    projectCases () {
      this.$store.dispatch('setElement', { path: 'DTProjectInfo.numberOfCases', value: this.projectInfo.cases.length })
      return this.projectInfo.cases.length
    }
  },
  watch: {
    keyForm () {
      this.value = null
      this.getProjects()
    },
    value () {
      if (this.value !== null) {
        this.getProjectDetails(this.value.name)
      } else {
        this.ProjectInfo = undefined
      }
    },
    toggleTypeOfProject (typeOfProject) {
      this.value = null
      this.getProjects()
      if (typeOfProject === 0) {
        this.showNewConnections = true
        this.showPlanning = false
      } else {
        this.showNewConnections = false
        this.showPlanning = true
      }
    }
  },
  created () {
    this.getProjects()
  },
  methods: {
    deleteProject () {
      // open alert
      this.$emit('deleteProject', this.value.name)
    },
    async openProject () {
      const details = await this.dtc.getProjectInfo(this.value.name)
      if (this.value.name) {
        this.$emit('openProject', this.showNewConnections, this.value.name, details)
      }
    },
    async getProjects () {
      this.dtc = new DigitalTwinCreator(this.$HEADER_HTTP, this.$API_HOST, this.$API_PORT,
        () => {}, console.error,
        console.warn,
        this.$DEBUG)
      this.projectsList = await this.dtc.getProjectList()
      this.loading = false
    },
    async getProjectDetails (projectName) {
      if (this.toggleTypeOfProject === 0) {
        this.projectInfo = await this.dtc.getProjectInfo(projectName)

        if (this.projectInfo >= 500) {
          this.showError = true
          this.numberError = this.projectInfo
          this.textError = 'Server'
        } else if (this.projectInfo >= 400) {
          this.showError = true
          this.numberError = this.projectInfo
          this.textError = 'Client'
        }
      } else {
        this.projectInfo = await this.dtc.getMontecarloInfo(projectName)
        this.$store.dispatch('setElement', { path: 'DTProject.networks', value: this.projectInfo.networks })
      }
    },
    hideError () {
      location.reload()
    }
  }
}
</script>
<style scoped>
.v-list--dense .v-list-item .v-list-item__content {
  padding: 0px 0;
}
.v-list-item {
  margin-top:0px;
}
.yellowPlexi{
  color:#F4C020;
}
.redPlexi{
  color:#b60c0c;
}
.mtop20{
  margin-top: 20px;
}
.mright20{
  margin-right: 20px;
}
::v-deep .v-list-item__content{
    text-align:left !important;
}

</style>
