<template>
  <div>
    <CMLoader
      v-if="uploading"
      :loading="uploading"
      :message="$t('importer.uploading_file') + ': ' + loadPercentage +'%'"
    />
    <v-form
      v-if="dataBase"
      ref="EventsDataLoadForm"
      v-model="valid"
      lazy-validation
    >
      <v-row
        v-if="!sent"
        align="center"
        align-content="center"
        justify="center"
        style="padding: 0px 20px"
      >
        <v-col
          cols="12"
          self-align="center"
          class="form-text"
          style="margin-top:30px"
        >
          <v-autocomplete
            v-model="dbValue"
            dark
            dense
            :items="dataBase"
            item-text="text"
            item-value="text"
            label="Database"
          />
        </v-col>
        <v-col
          v-if="dbFields && dbTables"
          cols="12"
          self-align="center"
         >
         <v-row>
           <v-col
          cols="12"
          self-align="center"
          class="form-text"
          style="margin-top:30px"
        >
          <v-autocomplete
            v-model="tableValue"
            dark
            dense
            :items="dbTables"
            item-text="text"
            item-value="text"
            label="Table"
          />
        </v-col>
         </v-row>
          <v-row>
             <v-col
              cols="12"
              self-align="center"
              style="margin-top:30px"
              class="form-text"
            >
              <v-autocomplete
                v-model="refValue"
                dark
                dense
                :items="refOptions"
                item-text="text"
                item-value="text"
                label="Ref"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              self-align="center"
              class="form-text"
            >
              <v-autocomplete
                v-model="timeValue"
                dark
                dense
                :items="timeOptions"
                item-text="text"
                item-value="text"
                label="Time"
              />
            </v-col>
          </v-row>
          <v-row
            v-for="(field,index) in dbFields"
            :key="index"
            justify="center"
          >
            <v-col
              cols="12"
              self-align="center"
              class="form-text"
            >
              <v-autocomplete
                v-model="eventsValues[index]"
                dark
                dense
                :items="eventsOptions[index]"
                :label="field"
                />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          self-align="center"
        >
          <v-btn
            v-if="!uploading"
            color="#f4c020"
            text
            style="margin:20px"
            :disabled="!formReady"
            @click="uploadEventsFile"
          >
            {{ $t('importer.load_file') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-else
        align="center"
        align-content="center"
        justify="center"
      >
        <v-col
          cols="12"
          self-align="center"
          style="margin:30px 10px 0px 10px"
          class="form-text"
        >    
          <v-alert  
            :type="response.type"
            :color="response.color"
            dismissible="true"
          >
            <p>{{ response.message }}</p>
          </v-alert>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>

import i18n from '@/plugins/i18n'
import { getDDBB, getDBFields, postEventFile } from '@/services/dataImporter'

export default {
  name: 'EventsDataUpload',

  components: {
    CMLoader: () => import('@/components/Common/CMLoader.vue')
  },

  props: {
    headers: {
      type: Array,
      default: null
    },
    content: {
      type: Object,
      default: null
    },
    parsed: {
      type: Boolean,
      default: false
    },
    fileData: {
      type: File,
      default: null
    },
    fileTitle: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      valid: true,
      sent: false,
      uploading: false,
      dataBase: null,
      dataBaseReady: false,
      dbValue: null,
      dbFields: null,
      tableValue: null,
      dbTables: null,
      fieldsValues: null,
      refValue: null,
      timeValue: null,
      eventsValues: null,
      formReady: false,
      batchNumRows: 100000, //number of lines
      currentBatch: 1,
      currentRow: 0,
      chunkFileName: `${this.fileTitle.slice(0, -4)}_1.csv`,
      chunkFileData: '',
      response: {
        message: '',
        type: 'info',
        color: '#ccc',
        code: 0
      },
      batchErrors: [],
      batchsLoaded: 0,
      loadPercentage: 0
    }
  },

  computed: {
    refOptions () {
      return this.headers.filter(header => header !== this.timeValue && !this.eventsValues.includes(header))
    },

    timeOptions () {
      return this.headers.filter(header => header !== this.refValue && !this.eventsValues.includes(header))
    },

    eventsOptions () {
      const events = new Array(this.dbFields.length)
      for (let i = 0; i < this.dbFields.length; i += 1) {
        events[i] = this.headers.filter(header => header !== this.refValue && header !== this.timeValue)
      }
      this.eventsValues.forEach((value, valueIndex) => {
        if (value !== null) {
          for (let i = 0; i < this.dbFields.length; i += 1) {
            if (valueIndex !== i) {
              events[i] = events[i].filter(header => header !== value)
            }
          }
        }
      })
      return events
    },

    configParams () {
      return {
        reference: this.refValue,
        timestamp: this.timeValue,
        dbname: this.dbValue,
        field_col: this.eventsValues.toString(),
        event_table: this.tableValue.toString()
      }
    },

    fileNameWithoutExtension() {
      return this.fileTitle.slice(0, -4)
    }
  },

  watch: {
    async dbValue () {
      const dbFieldsResponse = await getDBFields(this.dbValue)
      this.dbFields = dbFieldsResponse.event_fields
      this.dbTables = dbFieldsResponse.event_tables
      this.eventsValues = [this.dbFields.length]
      this.fieldsValues = [this.dbFields.length]
    },
    refValue () {
      this.checkFormReady()
    },
    timeValue () {
      this.checkFormReady()
    },
    eventsValues () {
      this.checkFormReady()
    }
  },

  async mounted () {
    this.dataBase = await getDDBB()
    this.signalsReady = true
  },

  methods: {
    checkFormReady () {
      if (this.eventsValues.length === this.dbFields.length && this.refValue !== null && this.timeValue !== null) {
        this.formReady = true
      } else {
        this.formReady = false
      }
    },

    handleResponses(message){
      message.forEach((msg) => {
        if (msg.response !== 'success') {
          this.batchErrors.push(msg.numBatch)
        }
      })
      if(this.batchErrors.length === 0) {
        this.response.code = 200
        this.response.type = 'success'
        this.response.color = '#089611'
        this.response.message = `${i18n.t('file')} ${this.fileTitle} ${i18n.t('importer.has_been_successfully_uploaded')}`
      }else {
        this.response.code = 500
        this.response.type = 'error'
        this.response.color = '#C1422E'
        this.response.message = `${i18n.t('importer.failed_uploading_file')} ${this.fileTitle} \n`
        this.batchErrors.forEach((batch) => {
          const initLine = batch === 1 ? 1 : (batch * this.batchNumRows) + 1
          const endLine = (initLine + this.batchNumRows) - 1
          this.response.message += `${i18n.t('importer.error_between_lines')} ${initLine} ${i18n.t('and')} ${endLine} \n`
        })
      }
    },
    
    fileUploaded(message){
      this.handleResponses(message)
      this.sent = true
      this.uploading = false
    },

    async postChunk (fileName, fileData, numBatch){
      const chunkFile = {
        data: new File([fileData], fileName, {type:"text/csv"}),
        title: fileName
      }
      const response = await postEventFile (chunkFile, this.configParams)
      this.batchsLoaded ++
      this.loadPercentage = (this.batchsLoaded / this.currentBatch) * 100
      return { response, numBatch}
    },


    splitContent (content, numRows){
      const totalFileRows = content.data.length
      return new Promise((resolve) => {
        const promises = []
        const headers = Object.values(content.data[0]).join(',') + '\n'
        content.data.forEach((row, index) => {
          if(this.currentRow === 0){
            this.chunkFileData += headers
            this.currentRow++
          }
          if(index > 1){
            this.chunkFileData += Object.values(row).join(',')+'\n'
            this.currentRow++ 
            if(this.currentRow >= numRows || this.currentRow == totalFileRows -1) { // Finishing the batch or the last row from csv 
              //initialize to start a new batch
              promises.push(this.postChunk(this.chunkFileName, this.chunkFileData, this.currentBatch))
              this.currentBatch ++
              this.currentRow = 0
              this.chunkFileName = `${this.fileNameWithoutExtension}_${this.currentBatch}.csv`
              this.chunkFileData = ''
            }
          }
        })
        Promise.all(promises).then(results => {     
          resolve(results)
        })
      })
    },

    async uploadEventsFile () {
      this.uploading = true
      const response = await this.splitContent (this.content, this.batchNumRows)
      this.fileUploaded(response)
    }
  }

}
</script>
<style scoped>
.form-text{
   color:#cccccc; font-family:'Faktum Light', sans-serif;
}
::v-deep .v-list-item__link{
    text-align:left !important;
}
::v-deep .v-list-item{
    text-align:left !important;
}
</style>
