<template>
  <v-card elevation="10">
    <v-card-title class="text-h5">
      {{ $t('plot.select_phase_export') }}
    </v-card-title>
    <v-card-text>
      <v-col
        class="p-2 pl-6"
        dense
      >
        <v-checkbox
          v-model="selectedPhase"
          label="PhaseA"
          value="PhaseA"
          color="#f4c020"
          dense
        />
        <v-checkbox
          v-model="selectedPhase"
          label="PhaseB"
          value="PhaseB"
          color="#f4c020"
          dense
        />

        <v-checkbox
          v-model="selectedPhase"
          label="PhaseC"
          value="PhaseC"
          color="#f4c020"
          dense
        />

        <v-checkbox
          v-model="selectedPhase"
          label="PhaseN"
          value="PhaseN"
          color="#f4c020"
          dense
        />
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="#f4c020"
        text
        @click="closeDownload"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        text
        :disabled="selectedPhase == 0"
        @click="downloadData"
      >
        {{ $t('plot.download') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import Papa from 'papaparse'
import { mapState } from 'vuex'

export default {
  name: 'DTExportPlotDataForm',
  props: {
    horizontalPlane: { type: Object, default: null },
    consolidatedPhase: { type: Object, default: null },
    plotType: { type: String, default: null}
  },

  data () {
    return {
      selectedPhase: ['PhaseA', 'PhaseB', 'PhaseC', 'PhaseN'],
      enhancedTime: [],
      time: []
    }
  },

  computed: {
    ...mapState({
      typeElement: (state) => state.typeElement,
      idElementToShow: (state) => state.idElementToShow
    })
  },

  watch: {
    selectedPhase () {
      return this.selectedPhase.length
    }
  },

  methods: {
    closeDownload () {
      this.$emit('closeDownload')
    },

    downloadData () {
      this.time = []
      this.enhancedTime = []

      this.time = this.horizontalPlane.map(xAxis => ({
        Datetime: xAxis
      }))

      this.enhancedTime = this.time

      this.selectedPhase.forEach((element) => {
        const result = this.consolidatedPhase.filter((word) => word[0].phase === element)

        const tempArray = result[0][0].yaxis.split(',')

        for (let index = 0; index < tempArray.length - 1; index++) {
          if (!tempArray[index] || isNaN(tempArray[index])) {
            tempArray[index] = 'NaN'
          }
          this.enhancedTime[index][`${element}`] = tempArray[index]
        }
      })

      // Convert JSON to CSV
      const csvData = Papa.unparse(this.enhancedTime, {
        quotes: false, // true includes the quotes on the string
        header: true // Include headers in the CSV output
      })

      // Download CSV
      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)

      // Create a link element and click it to trigger the download
      const a = document.createElement('a')
      a.href = url
      a.download = this.typeElement + '_' + this.plotType.replace(/\s/g, '').toLowerCase() + '_' + this.idElementToShow + '.csv'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      this.enhancedTime = []
      this.enhancedTime.length = 0
      this.selectedPhase = ['PhaseA', 'PhaseB', 'PhaseC', 'PhaseN']

      this.$emit('closeDownload')
    }
  }
}
</script>
