<template>
  <div>
    <v-expansion-panels
      v-model="panel"
      dark
      accordion
      multiple
    >
      <v-expansion-panel :key="0">
        <v-expansion-panel-header :hide-actions="false">
          {{ $t('grid_panel.general_information') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row style="margin-top:0px;">
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('name') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.name }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              ID:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.code }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('description') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.description }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid_panel.num_transformers') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.countTransformer }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid_panel.num_station_sup') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.stationSupervisors }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid_panel.num_line_sup') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.lineSupervisors }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid_panel.num_lines') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.totalLinesCount }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid_panel.num_active_lines') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.activeLinesCount }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-for="(trafo,i) in info.transformers"
        :key="'trafo'+i"
      >
        <v-expansion-panel-header :hide-actions="false">
          {{ i }} - {{ $t('grid_panel.transformer_information') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row style="margin-top:0px">
            <v-col
              cols="12"
              sm="5"
            >
              ID:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ i }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('description') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ trafo.description }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              Smax:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ trafo.smax }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              rp:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ trafo.rph }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              xph:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ trafo.xph }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              rn:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ trafo.rn }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              xn:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ trafo.xn }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid_panel.tap_max') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ trafo.tap_max }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid_panel.tap_min') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ trafo.tap_min }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid_panel.tap_positions') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ trafo.tap_positions }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid_panel.voltage_regulator') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ trafo.voltage_regultar }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row style="margin-bottom:10px">
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('installation_date') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ trafo.init_dt }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>

export default {
  name: 'StationDetails',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    network: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      panel: [0]
    }
  }
}
</script>
<style scoped>
.v-expansion-panel-content{
    font-size:14px
}
.col-12{
    padding:1px 15px !important;
    text-align:left;
}
.hr-separator-dark{
  margin: 12px 0;
  border-top: 0.5px solid #444;
  border-bottom: none;
  border-left: none;
  border-right:none;
}
</style>
