import { datadogRum } from '@datadog/browser-rum'

const isDatadogActive = ((process.env.VUE_APP_IS_DATADOG_ACTIVE ? process.env.VUE_APP_IS_DATADOG_ACTIVE : '{{VUE_APP_IS_DATADOG_ACTIVE}}') === 'true')

if (isDatadogActive) {

  const tracingUrlsStr = process.env.ALLOWED_TRACING_URLS || '';
  const allowedTracingUrls = tracingUrlsStr.split(',').map(url => url.trim()).filter(url => url);

  let datadogApplicationId = process.env.VUE_APP_DATADOG_APPLICATION_ID ? process.env.VUE_APP_DATADOG_APPLICATION_ID : '{{VUE_APP_DATADOG_APPLICATION_ID}}'
  let datadogClientToken = process.env.VUE_APP_DATADOG_CLIENT_TOKEN ? process.env.VUE_APP_DATADOG_CLIENT_TOKEN : '{{VUE_APP_DATADOG_CLIENT_TOKEN}}'
  let datadogSite = process.env.VUE_APP_DATADOG_SITE ? process.env.VUE_APP_DATADOG_SITE : '{{VUE_APP_DATADOG_SITE}}'
  let datadogService = process.env.VUE_APP_DATADOG_SERVICE ? process.env.VUE_APP_DATADOG_SERVICE : '{{VUE_APP_DATADOG_SERVICE}}'
  let datadogEnv = process.env.VUE_APP_DATADOG_ENV ? process.env.VUE_APP_DATADOG_ENV : '{{VUE_APP_DATADOG_ENV}}'
  let datadogSessionSampleRate = process.env.VUE_APP_DATADOG_SESSION_SAMPLE_RATE ? process.env.VUE_APP_DATADOG_SESSION_SAMPLE_RATE : '{{VUE_APP_DATADOG_SESSION_SAMPLE_RATE}}'
  let datadogSessionReplaySample = process.env.VUE_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE ? process.env.VUE_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE : '{{VUE_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE}}'
  let datadogTrackUserInteractions = process.env.VUE_APP_DATADOG_TRACK_USER_INTERACTIONS ? process.env.VUE_APP_DATADOG_TRACK_USER_INTERACTIONS : '{{VUE_APP_DATADOG_TRACK_USER_INTERACTIONS}}'
  let datadogTrackResources = process.env.VUE_APP_DATADOG_TRACK_RESOURCES ? process.env.VUE_APP_DATADOG_TRACK_RESOURCES : '{{VUE_APP_DATADOG_TRACK_RESOURCES}}'
  let datadogTrackLongTasks = process.env.VUE_APP_DATADOG_TRACK_LONG_TASKS ? process.env.VUE_APP_DATADOG_TRACK_LONG_TASKS : '{{VUE_APP_DATADOG_TRACK_LONG_TASKS}}'
  let datadogTrackFrustrations = process.env.VUE_APP_DATADOG_TRACK_FRUSTRATIONS ? process.env.VUE_APP_DATADOG_TRACK_FRUSTRATIONS : '{{VUE_APP_DATADOG_TRACK_FRUSTRATIONS}}'
  let datadogPrivacyLevel = process.env.VUE_APP_DATADOG_DEFAULT_PRIVACY_LEVEL ? process.env.VUE_APP_DATADOG_DEFAULT_PRIVACY_LEVEL : '{{VUE_APP_DATADOG_DEFAULT_PRIVACY_LEVEL}}'

  datadogRum.init({
    applicationId: datadogApplicationId,
    clientToken: datadogClientToken,
    site: datadogSite,
    service: datadogService,
    env: datadogEnv,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: Number(datadogSessionSampleRate),
    sessionReplaySampleRate: Number(datadogSessionReplaySample),
    trackUserInteractions: (datadogTrackUserInteractions === 'true'),
    trackResources: (datadogTrackResources === 'true'),
    trackLongTasks: (datadogTrackLongTasks === 'true'),
    trackFrustrations: (datadogTrackFrustrations === 'true'),
    defaultPrivacyLevel: datadogPrivacyLevel,
    allowedTracingUrls: allowedTracingUrls
  })
  datadogRum.startSessionReplayRecording()
}
