<template>
  <div>
    <div class="custom-container">
      <DTProjectInfoButton />
      <v-btn
        icon
        :disabled="deleteIcon"
        data-cy="dt-delete-case"
        color="white"
        light
        class="delete-icon"
        @click="deleteCase"
      >
        <v-icon>{{ trashIcon }}</v-icon>
      </v-btn>
      <v-select
        v-model="currentCase"
        :items="currentCases"
        :disabled="creatingCase"
        data-cy="dt-select-case"
        placeholder="Select Case"
        item-text="name"
        item-value="name"
        solo
        dense
        clearable
        @change="setCase"
      />
      <v-btn
        elevation="2"
        :disabled="creatingCase"
        data-cy="dt-new-editing-case"
        @click="newCaseCreate"
      >
        {{ creatingCase ? $t('new_connections.editing') : $t('new') }}
      </v-btn>
      <template v-if="currentCase">
        <v-btn
          elevation="2"
          :disabled="!creatingCase"
          data-cy="dt-simulate-case"
          class="simulate-button"
          @click="saveCase"
        >
          {{ $t('dt.simulate') }}
        </v-btn>
      </template>
    </div>
    <v-dialog
      v-model="dialog"
      style="z-index: 501"
      max-width="450"
      overlay-opacity="0.6"
      persistent
    >
      <v-card elevation="10">
        <v-form
          ref="NewCaseForm"
          v-model="valid"
          lazy-validation
        >
          <v-card-title class="text-h5">
            {{ $t('new_connections.new_case') }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="newCaseName"
                    :rules="VALIDATIONS.caseName"
                    data-cy="dt-new-case-name"
                    :label="$t('new_connections.case_name')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  data-cy="dt-new-case-reference"
                >
                  <v-select
                    v-model="referenceCase"
                    :items="currentCases"
                    :label="$t('new_connections.reference_case')"
                    item-text="name"
                    item-value="name"
                    @change="(e) => setCase(e, true)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="uniformLoadScale"
                    :rules="VALIDATIONS.loadScale"
                    data-cy="dt-new-case-load-scale"
                    :label="$t('new_connections.uniform_load_scale')"
                    type="number"
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-alert
                    v-if="feedback"
                    type="error"
                    v-text="feedback"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="#f4c020"
              text
              @click="newCaseCancel"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              :disabled="!valid || feedback"
              data-cy="dt-new-case-add"
              color="#f4c020"
              text
              @click="addCase"
            >
              {{ $t('add') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { get } from 'lodash'
import { DTCHANGES_EMPTY, ICONS, VALIDATIONS } from '@/store/constants'
import { mdiTrashCan } from '@mdi/js'
import DTProjectInfoButton from '@/components/DigitalTwin/DTProjectInfoButton'
import proj4 from 'proj4'
import vuexMixin from '@/mixins/vuexMixin'

export default {
  name: 'MapCaseSelector',

  components: { DTProjectInfoButton },

  mixins: [vuexMixin],

  data: () => ({
    trashIcon: mdiTrashCan,
    BASE_CASE: 'base',
    valid: true,
    newCaseName: undefined,
    currentCase: '',
    referenceCase: 'base',
    previousCase: 'base',
    uniformLoadScale: 1,
    dialog: false,
    currentCases: [],
    originalCases: [],
    feedback: false
  }),

  computed: {
    ...mapState(['DTResults', 'DTChanges', 'creatingCase', 'violations']),
    ...mapGetters(['connectionPoints', 'cases', 'fuses', 'lines', 'stations']),

    simulatingMsg () {
      return this.$t('new_connections.simulating_case_msg')
    },

    currentChanges () {
      const { mod: currentChanges } = this.getCaseByName(
        this.currentCases,
        this.currentCase
      )
      return currentChanges
    },

    hasChanged () {
      const previousChanges = get(
        this,
        `DTResults.cases.${this.currentCase}.mod`
      )

      return this.currentChanges
        ? JSON.stringify(previousChanges) !==
            JSON.stringify(this.currentChanges)
        : false
    },

    deleteIcon () {
      return this.currentCase === this.BASE_CASE || !this.currentCase
    },

    VALIDATIONS () {
      return VALIDATIONS
    }
  },

  watch: {
    currentCase (val) {
      this.$store.dispatch('setElement', {
        path: 'currentCase',
        value: val
      })

      if (val === this.BASE_CASE) {
        this.$store.dispatch('setElement', {
          path: 'DTChanges',
          value: DTCHANGES_EMPTY()
        })

        this.$store.dispatch('setElement', {
          path: 'markers',
          value: []
        })
      }
    },

    newCaseName (val) {
      const previousCases = this.currentCases.map((c) => c.name)
      const isAlreadyCreated = previousCases.includes(val)

      this.feedback = isAlreadyCreated
        ? `Case ${val} is already exists!`
        : false
    },

    referenceCase: {
      handler (val) {
        this.$store.dispatch('setElement', {
          path: 'referenceCase',
          value: val
        })
      },
      immediate: true
    },

    DTChanges: {
      handler (val) {
        const caseIndex = this.currentCases.findIndex(
          (c) => c.name === this.currentCase
        )
        const refIndex = this.originalCases.findIndex(
          (c) => c.name === this.referenceCase
        )

        this.currentCases[caseIndex].mod = val
        refIndex >= 0 &&
          (this.currentCases[refIndex].mod = this.originalCases[refIndex].mod)
      },
      deep: true
    }
  },

  created () {
    this.currentCases = this.cases
    this.currentCase = 'base'
    /* cases have the information of the project cases from store getter */
    this.originalCases = JSON.parse(JSON.stringify(this.cases))
    /* referenceCase is base when the component is created */
    this.$store.dispatch('setElement', {
      path: 'referenceCase',
      value: this.referenceCase
    })
  },

  methods: {
    setPositionMarker (id, type) {
      const result = this.setEntityMarker(id, type) || {}
      let value = []
      switch (type) {
        case 'Line':
          if (result.latLng.length === 2) {
            value = [
              (result.latLng[0][0] + result.latLng[1][0]) / 2,
              (result.latLng[0][1] + result.latLng[1][1]) / 2
            ]
          } else {
            const centeredIndex = Math.round(result.latLng.length / 2)
            value = result.latLng[centeredIndex]
          }
          break
        case 'CGP':
        case 'ConnectionPoint':
          value = result.latLng || []
          break
        case 'Switch': // Fuses
          value = result?.latLngs?.[0]
          break
        case 'Station':
          value = result.latLng || []
          break
      }
      return value
    },

    setEntityMarker (id, type) {
      // 1. Line 2.ConnectionPoint 3.Fuse 4.Station
      let value = null
      switch (type) {
        case 'Line':
          value = this.lines.find((c) => c.ID === id)
          break
        case 'CGP':
        case 'ConnectionPoint':
          value = this.connectionPoints.find((c) => c.ID === id)
          break
        case 'Switch': // Fuses
          value = this.fuses.find((c) => c.ID === id)
          break
        case 'Station':
          value = this.stations.find((c) => c.ID === id)
          break
      }
      return value
    },

    setNewCGPPositionMarker (busIndex, type) {
      // IMPORTANT: Bus = Node in the way that we use it. The good term is bus, becouse in reality bus can has more than one nodes
      const busX = this.$store.state.DTResults.Results.Bus_X[busIndex]
      const busY = this.$store.state.DTResults.Results.Bus_Y[busIndex]

      const latLng = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [busX, busY])
      // proj4 returns in order longitude/latitude
      return [latLng[1], latLng[0]]
    },

    setNewCGPEntityMarker (item, busIndex, type) {
      return {
        ID: item,
        X: this.$store.state.DTResults.Results.Bus_X[busIndex],
        Y: this.$store.state.DTResults.Results.Bus_Y[busIndex],
        latLng: this.setNewCGPPositionMarker(busIndex, type)
      }
    },

    setMarkersFromDT () {
      if (this.currentCase === 'base') { return [] }
      const entriesChanges = Object.entries(this.currentChanges)
      // 1. Select the DTChanges that will need a marker
      // Disable_Element, cable_change (Could be in different elements)
      const withoutConnectionPoint = entriesChanges.filter(
        ([type, data]) => type === 'Disable_Element' || type === 'cable_change'
      )
      // Switch, CGP_New, PV, EV, HP, Phase, CP, ESS
      const onlyDevices = entriesChanges.filter(
        ([type, data]) => type !== 'LoadScale' && type !== 'Disable_Element' && type !== 'NewBusCount' && type !== 'cable_change'
      )
      // We will use this lists after to know if the element added is in a CGP_New
      const cgpNewObj = entriesChanges.find(([key]) => key === 'CGP_New')
      const newCGPids = cgpNewObj[1].CGP // List of new CGP id's
      const newCGPNodes = cgpNewObj[1].Node // List of the Nodes with a CGP_New

      // 2. Create arrays of markers
      const otherMarkers = []
      withoutConnectionPoint.forEach(([type, data]) => {
        if (type === 'Disable_Element') {
          for (let i = 0; i < data.id.length; i++) {
            const typeElement = this.typeElementName(data.element_type[i])
            otherMarkers.push({
              id: `${data.id[i]}-0`,
              type,
              element: this.setEntityMarker(data.id[i], typeElement.name),
              icon: ICONS[`Disable_Element_${typeElement.name}`],
              latLng: this.setPositionMarker(data.id[i], typeElement.name),
              typeElement: typeElement.contextMenu
            })
          }
        } else if (type === 'cable_change') {
          data.forEach((element) => {
            otherMarkers.push({
              id: `${element.Line_id}-0`,
              type,
              element: this.setEntityMarker(element.Line_id, 'Line'),
              icon: ICONS[type],
              latLng: this.setPositionMarker(element.Line_id, 'Line'),
              typeElement: 'contextMenuLine'
            })
          })
        }
      })

      const connectionPoints = []
      const cgpMarkers = onlyDevices.flatMap(([type, data]) => {
        // Most of the markers have CGP, if not Node to know where to put it. data is for the 'Switch', because is only a list of id's
        const sortedData = data.CGP ? [...data.CGP.sort()] : data.Node ? [...data.Node.sort()] : data
        let customIndex = 0
        return sortedData.map((item, index, arr) => { // Only id's
          if (type === 'Switch') {
            return {
              id: `${item}-0`,
              type,
              element: this.setEntityMarker(item, 'Switch'),
              icon: ICONS[type],
              latLng: this.setPositionMarker(item, 'Switch'),
              typeElement: 'contextMenuFuse'
            }
          } else if (type === 'CGP_New') {
            const busId = data.Node[index]
            const busIndex = this.$store.state.DTResults.Results.Bus_ID.indexOf(busId)

            return {
              id: `${item}-0`,
              type,
              element: this.setNewCGPEntityMarker(item, busIndex, 'CGP'),
              icon: ICONS[type],
              latLng: this.setNewCGPPositionMarker(busIndex, 'CGP'),
              typeElement: 'contextMenuConn'
            }
          } else if (type === 'ESS') {
            // For ESS, 'item' comes with the Node, we will change it here (the id doesn't comes from DB and we will search it)
            const busIndex = this.$store.state.DTResults.Results.Bus_ID.indexOf(item)
            const busX = this.$store.state.DTResults.Results.Bus_X[busIndex]
            const busY = this.$store.state.DTResults.Results.Bus_Y[busIndex]

            // If Node is on CGP_New then is a CGP that wasn't originally on the project
            const newCGPNodeIndex = newCGPNodes.indexOf(item)
            if (newCGPNodeIndex < 0) {
              // As coordinates are not the same in Nodes than CGP, we search for the near
              const cgpDistance = this.connectionPoints.reduce((nearest, cgp) => {
                  const distance = Math.sqrt(Math.pow(busX - cgp.X, 2) + Math.pow(busY - cgp.Y, 2))
                  if (distance < nearest.distance) {
                    return { cgp, distance }
                  }
                  return nearest
              }, { cgp: null, distance: Infinity })
              item = cgpDistance.cgp.ID
            } else {
              item = newCGPids[newCGPNodeIndex]
            }

            // We will add this marker with the return at the end
          }

          customIndex =
            item === arr[index - 1] || connectionPoints.includes(item)
              ? customIndex + 1
              : 0

          connectionPoints.push(item)

          // Know if this marker is from a CGP_New or normal CGP
          const newCGPIndex = newCGPids.indexOf(item)

          return {
            id: `${item}-${customIndex}`,
            type,
            element: newCGPIndex < 0 ? this.setEntityMarker(item, 'CGP') : this.setNewCGPEntityMarker(item, this.$store.state.DTResults.Results.Bus_ID.indexOf(newCGPNodes[newCGPIndex]), 'CGP'),
            icon: ICONS[type],
            latLng: newCGPIndex < 0 ? this.setPositionMarker(item, 'CGP') : this.setNewCGPPositionMarker(this.$store.state.DTResults.Results.Bus_ID.indexOf(newCGPNodes[newCGPIndex]), 'CGP'),
            typeElement: 'contextMenuConn'
          }
        })
      })
      // 3. Unite and send both arrays of markers
      return cgpMarkers.concat(otherMarkers)
    },

    typeElementName (typeElementNumber) {
      let name = ''
      let contextMenu = ''
      switch (typeElementNumber) {
        case 1:
          name = 'Line'
          contextMenu = 'contextMenuLine'
          break
        case 2:
          name = 'ConnectionPoint' // CGP
          contextMenu = 'contextMenuConn'
          break
        case 3:
          name = 'Switch'
          contextMenu = 'contextMenuFuse'
          break
        case 4:
          name = 'Station'
          contextMenu = 'contextMenuStation'
          break
      }

      return {
        name,
        contextMenu
      }
    },
    async setCase (_, ref = false) {
      if (this.currentCase) {
        const setElements = ([path, value]) =>
          this.$store.dispatch('setElement', { path, value })

        this.$store.dispatch('setElement', { path: 'markers', value: [] })
        ref && (this.currentCase = this.referenceCase)

        await this.DTResults.prepareTopology(this.currentCase, this.previousCase)
        await this.DTResults.adjustBusCount(this.currentChanges.NewBusCount) // Calculate well plots with ESS

        const setters = [
          ['simulatedCase', this.currentCase],
          ['currentCase', this.currentCase],
          ['markers', this.setMarkersFromDT()],
          ['DTChanges', this.currentChanges]
        ]

        setters.forEach(setElements)
        this.previousCase = this.currentCase
      }
    },

    newCaseCreate () {
      this.dialog = true
      this.$store.dispatch('setElement', { path: 'creatingCase', value: true })
      this.referenceCase = this.currentCase
      this.$emit('allowContextMenu', true)
    },

    newCaseCancel () {
      this.dialog = false
      this.$store.dispatch('setElement', { path: 'creatingCase', value: false })
      this.referenceCase = this.originalCases.find(
        (c) => c.name === this.referenceCase
      )
      this.$emit('allowContextMenu', false)
    },

    async addCase () {
      const validation = this.$refs.NewCaseForm.validate()
      if (validation) {
        this.$store.dispatch('setElement', {
          path: 'DTChanges.LoadScale',
          value: parseFloat(this.uniformLoadScale)
        })

        this.currentCases.push({
          idx: Object.keys(this.DTResults.cases).length,
          name: this.newCaseName,
          mod: this.currentChanges
        })

        this.dialog = false
        this.currentCase = this.newCaseName
        this.$store.dispatch('setElement', {
          path: 'simulatedCase',
          value: this.referenceCase
        })
      }
    },

    async saveCase () {
      if (this.currentCase) {
        this.$emit('toggleLoading', this.simulatingMsg)

        const { name, data } = await this.DTResults.addCase(
          this.currentCase,
          this.BASE_CASE,
          this.currentChanges
        )

        this.$store.dispatch('setElement', {
          path: 'DTChanges',
          value: data
        })

        const newCase = {
          idx: Object.keys(this.DTResults.cases).length,
          name,
          mod: data
        }

        this.$store.dispatch('setElement', {
          path: `DTResults.cases.${this.currentCase}`,
          value: newCase,
          force: true
        })

        await this.DTResults.simulateCase(this.currentCase, this.referenceCase)

        this.DTResults.adjustBusCount(newCase.mod.NewBusCount) // Calculate well plots with ESS

        this.previousCase = this.currentCase
        this.originalCases.push(JSON.parse(JSON.stringify(newCase)))
        this.$store.dispatch('setElement', {
          path: 'creatingCase',
          value: false
        })
        this.newCaseName = undefined
        this.$store.dispatch('setElement', {
          path: 'simulatedCase',
          value: this.currentCase
        })

        this.$emit('allowContextMenu', false)
        this.$emit('toggleLoading')

        if (this.violations.data.length) {
          this.setVuexElement({
            path: 'violations.dialog',
            value: true
          })
        }
      }
    },

    async deleteCase () {
      const caseToDelete = this.currentCase
      this.currentCase = this.BASE_CASE
      this.previousCase = this.BASE_CASE

      const caseCreated = this.DTResults.cases[caseToDelete]

      if (caseCreated) {
        await this.DTResults.deleteCase(caseToDelete)

        this.$store.dispatch('deleteElement', {
          path: `DTResults.cases.${caseToDelete}`,
          force: true
        })

        this.DTResults.adjustBusCount(0) // Calculate well plots with ESS
      }

      this.currentCases = this.removeCaseByName(
        this.currentCases,
        caseToDelete
      )
      this.originalCases = this.removeCaseByName(
        this.originalCases,
        caseToDelete
      )

      this.$store.dispatch('setElement', {
        path: 'simulatedCase',
        value: this.currentCase
      })
      this.$store.dispatch('setElement', { path: 'creatingCase', value: false })
      this.$emit('allowContextMenu', false)
    },

    getCaseByName (allCasesArray, caseName) {
      return allCasesArray.find((c) => c.name === caseName) || {}
    },

    removeCaseByName (allCasesArray, caseName) {
      return allCasesArray.filter((c) => c.name !== caseName)
    }
  }
}
</script>

<style scoped>
.custom-container {
  display: grid;
  grid-template-columns: auto 0.11fr auto auto auto;
  gap: 10px;
  z-index: 500;
  position: absolute;
  top: 12px;
  max-width: 500px;
  left: 280px;
  height: 30px;
  line-height: 30px;
}
.simulate-button {
  min-width:100px !important;
}

.delete-icon {
  margin-right:-20px !important;
}

::v-deep .v-list-item__content{
    text-align:left !important;
}
</style>
