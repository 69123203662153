<template>
  <v-app id="plot-generic">
    <div class="plot-container">
      <div
        :id="name"
        class="plotly-container"
      >
        <Plotly
          :key="cphase"
          :name="name"
          :data="getDataPlot"
          :layout="getLayoutPlot"
          :displaylogo="false"
          @legendclick="clickLegend($event)"
        />
      </div>
      <div class="button-container">
        <button @click="openDialog">
          <v-icon>{{ pencilIcon }}</v-icon>
        </button>
      </div>

      <div class="button-download">
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <button
              v-bind="attrs"
              v-on="on"
              @click="openDownload"
            >
              <v-icon>{{ downloadIcon }}</v-icon>
            </button>
          </template>
          <span>Download</span>
        </v-tooltip>
      </div>
    </div>
    <v-dialog
      v-model="dialogVisible"
      style="z-index:501"
      max-width="450"
      overlay-opacity="0.5"
    >
      <DTEditOverloadAnalysis
        v-if="dialogVisible"
        :id-station="stationOverloadLimits.idStation"
        :consumption-limit-p="stationOverloadLimits.consumptionLimitP"
        :consumption-limit-q="stationOverloadLimits.consumptionLimitQ"
        :injection-limit-p="stationOverloadLimits.injectionLimitP"
        :injection-limit-q="stationOverloadLimits.injectionLimitQ"
        @closeDialog="closeDialog"
      />
    </v-dialog>

    <v-dialog
      v-model="downloadDialog"
      max-width="400"
      overlay-opacity="0.5"
    >
      <DTExportPlotData
        :horizontal-plane="horizontalPlane"
        :consolidated-phase="consolidatedPhase"
        @closeDownload="closeDownload"
      />
    </v-dialog>
  </v-app>
</template>

<script>

import { Plotly } from 'vue-plotly'
import { mapState } from 'vuex'
import { mdiLeadPencil, mdiDownload } from '@mdi/js'
import DTEditOverloadAnalysis from '@/components/DigitalTwin/Forms/DTEditOverloadAnalysisForm.vue'
import DTExportPlotData from '@/components/DigitalTwin/Forms/DTExportPlotDataForm.vue'

export default {
  name: 'DtPanelPlotStationPower',
  components: {
    Plotly,
    DTEditOverloadAnalysis,
    DTExportPlotData
  },
  props: {
    name: { type: String, default: null },
    yAxis: { type: String, default: null },
    xAxis: { type: String, default: null },
    pavY: { type: Float32Array, default: null },
    pbvY: { type: Float32Array, default: null },
    pcvY: { type: Float32Array, default: null },
    pnvY: { type: Float32Array, default: null },
    samplingPeriod: { type: Number, default: null }
  },
  data: () => ({
    pencilIcon: mdiLeadPencil,
    downloadIcon: mdiDownload,
    dialogVisible: false,
    downloadDialog: false,
    cphase: 0,
    upperLimit: 0,
    lowerLimit: 0,
    stationOverloadLimits: {
      idStation: null,
      consumptionLimitP: null,
      consumptionLimitQ: null,
      injectionLimitP: null,
      injectionLimitQ: null
    },
    horizontalPlane: null,
    consolidatedPhase: []
  }),
  computed: {
    ...mapState({
      vphaseA: (state) => state.Plots.phaseA,
      vphaseB: (state) => state.Plots.phaseB,
      vphaseC: (state) => state.Plots.phaseC,
      vphaseN: (state) => state.Plots.phaseN,
      sim: (state) => state.DTProject.sim,
      overloadAnalysis: (state) => state.overloadAnalysis,
      click: (state) => state.click
    }),

    getDataPlot () {
      this.stationOverloadLimits = this.obtainStationOverloadLimits(this.click.idElement.toString())

      if (this.name === 'Active Power') {
        this.upperLimit = this.stationOverloadLimits.consumptionLimitP
        this.lowerLimit = this.stationOverloadLimits.injectionLimitP
      } else {
        this.upperLimit = this.stationOverloadLimits.consumptionLimitQ
        this.lowerLimit = this.stationOverloadLimits.injectionLimitQ
      }
      // Variables for Plot
      const arrayX = []

      const date = new Date(this.sim.InitDate)
      const gap = date.getTimezoneOffset()

      let initDate = new Date(this.sim.InitDate).getTime()
      initDate += gap * 60 * 1000
      const endDate = new Date(this.sim.EndDate).getTime()

      let steps = (endDate - initDate) / (1000 * this.samplingPeriod)
      steps += 23 * (3600/this.samplingPeriod)

      const options = {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'
      }

      for (let i = 0; i < steps; i += 1) {
        const d = new Date()
        d.setTime(initDate + (i * parseFloat(this.samplingPeriod * 1000)))
        arrayX.push(d.toLocaleDateString('en-UK', options))
      }

      this.horizontalPlane = arrayX

      const phases = ['A', 'B', 'C', 'N']
      const phasesData = []
      const shadersData = []

      this.consolidatedPhase = []

      phases.forEach(phase => {
        const currentPhase = {
          x: arrayX,
          y: this[`p${phase.toLowerCase()}vY`],
          type: 'scatter',
          mode: 'lines',
          name: `Phase${phase}`,
          line: {
            shape: 'hvh'
          },
          visible: this[`vphase${phase}`]
        }

        const consolidated = []
        consolidated.push({ phase: `${currentPhase.name}`, yaxis: `${currentPhase.y}` })
        this.consolidatedPhase.push(consolidated)

        phasesData.push(currentPhase)
      })

      const phaseTotalY = phasesData[0].y.map(function (num, idx) {
        return num + phasesData[1].y[idx] + phasesData[2].y[idx] // A + B + C
      })

      const totalPhase = {
        x: arrayX,
        y: phaseTotalY,
        type: 'scatter',
        mode: 'lines',
        name: 'Total',
        line: {
          shape: 'hvh'
        },
        visible: this.Total
      }

      const shadersUpper = this.upperData(totalPhase, this.upperLimit)
      const shadersLower = this.lowerData(totalPhase, -(this.lowerLimit))

      shadersData.push(...shadersUpper, ...shadersLower)

      return phasesData.concat(totalPhase).concat(shadersData)
    },
    getLayoutPlot () {
      const layout = {
        title: this.name,
        showlegend: true,
        legendgroup: 'main',
        legend: {
          orientation: 'h',
          valign: 'top',
          yanchor: 'top',
          bgcolor: 'rgba(0,0,0,0)',
          font: {
            size: '8',
            family: 'Faktum Light'
          }
        },
        height: 450,
        margin: {
          l: 60,
          r: 20,
          b: 100,
          t: 80,
          pad: 5
        },
        xaxis: {
          title: this.xAxis,
          linecolor: '#fff',
          nticks: 5,
          gridcolor: '#2c2c2c',
          showspikes: true,
          spikethickness: 2,
          spikedash: 'dot',
          spikecolor: '#fff',
          spikemode: 'across',
          tickangle: 0,
          tickfont: {
            size: 10
          }
        },
        yaxis: {
          title: this.yAxis,
          linecolor: '#fff',
          gridcolor: '#2c2c2c'
        },
        paper_bgcolor: '#000',
        plot_bgcolor: '#191919',
        font: {
          color: '#fff',
          family: 'Faktum Light'
        },
        hovermode: 'x',
        modebar: {
          activecolor: '#f4c020',
          color: '#fff',
          remove: ['zoomin2d', 'zoomout2d', 'resetscale2d']
        },
        line: {
          color: '#f4c020',
          width: 3
        },
        shapes: [
          // Top Horizontal Line
          {
            type: 'line',
            x0: 0,
            y0: this.upperLimit,
            x1: this.pavY.length,
            y1: this.upperLimit,
            line: {
              color: 'rgb(255, 0, 229)',
              width: 4,
              dash: 'dashdot'
            }
          },
          // Bottom Horizontal Line
          {
            type: 'line',
            x0: 0,
            y0: -(this.lowerLimit),
            x1: this.pavY.length,
            y1: -(this.lowerLimit),
            line: {
              color: 'rgb(50, 171, 96)',
              width: 4,
              dash: 'dashdot'
            }
          }
        ]
      }

      return layout
    }
  },
  methods: {
    openDialog () {
      this.dialogVisible = true
    },
    closeDialog () {
      this.dialogVisible = false
    },

    openDownload () {
      this.downloadDialog = true
    },
    closeDownload () {
      this.downloadDialog = false
    },

    clickLegend (evt) {
      const phases = ['PhaseA', 'phaseB', 'phaseC', 'phaseN']
      const phaseIndex = evt.expandedIndex

      this.$store.dispatch('setElement', { path: `Plots.${phases[phaseIndex]}`, value: 'true', root: 'Plots' })

      if (this.$store.state.Plots[phases[phaseIndex]] === 'true') {
        this.$store.dispatch('setElement', { path: `Plots.${phases[phaseIndex]}`, value: 'legendonly', root: 'Plots' })
      }

      this.cphase = phaseIndex
    },

    upperData (data, threshold) {
      const shaders = []

      const emptyFiller = {
        x: [],
        y: [],
        fill: 'toself',
        type: 'scatter',
        mode: 'lines',
        line: {
          width: 1,
          shape: 'hvh'
        },
        showlegend: false,
        legendgroup: 'main'
      }

      shaders.push(emptyFiller)

      for (let i = 0; i < data.y.length; i += 1) {
        if (data.y[i] >= threshold) {
          if (i !== 0 && data.y[i - 1] < threshold) {
            shaders[shaders.length - 1].x.push(data.x[i - 1])
            shaders[shaders.length - 1].y.push(threshold)
          }
          shaders[shaders.length - 1].x.push(data.x[i])
          shaders[shaders.length - 1].y.push(data.y[i])
        } else if (shaders[shaders.length - 1].x.length > 0) {
          if (i !== 0 && data.y[i - 1] !== threshold) {
            shaders[shaders.length - 1].x.push(data.x[i])
            shaders[shaders.length - 1].y.push(threshold)
          }
        }
      }

      return shaders
    },

    lowerData (data, threshold) {
      const shaders = []

      const emptyFiller = {
        x: [],
        y: [],
        fill: 'toself',
        type: 'scatter',
        mode: 'lines',
        line: {
          width: 1,
          shape: 'hvh'
        },
        opacity: 0.5,
        showlegend: false,
        legendgroup: 'main'
      }

      shaders.push(emptyFiller)

      for (let i = 0; i <= data.y.length; i += 1) {
        if (data.y[i] <= threshold) {
          if (i !== 0 && data.y[i] < threshold) {
            shaders[shaders.length - 1].x.push(data.x[i + 1])
            shaders[shaders.length - 1].y.push(threshold)
            shaders[shaders.length - 1].x.push(data.x[i])
            shaders[shaders.length - 1].y.push(threshold)
          }

          shaders[shaders.length - 1].x.push(data.x[i - 1])
          shaders[shaders.length - 1].y.push(data.y[i])
        } else if (shaders[shaders.length - 1].x.length > 0) {
          if (i !== 0 && data.y[i - 1] !== threshold) {
            shaders[shaders.length - 1].x.push(data.x[i + 1])
            shaders[shaders.length - 1].y.push(threshold)
            shaders[shaders.length - 1].x.push(data.x[i])
            shaders[shaders.length - 1].y.push(threshold)
          }
        }
      }
      return shaders
    },
    obtainStationOverloadLimits (id) {
      return this.overloadAnalysis.find(station => station.idStation === id)
    }
  }
}
</script>
<style>
.plot-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.plotly-container {
  flex: 1;
}

.button-container {
  position: absolute;
  top: 30px;
  right: 10px;
}

.button-download {
  position: absolute;
  top: 30px;
  right: 50px;
}
</style>
