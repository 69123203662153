<template>
  <v-dialog
    v-model="showForm"
    style="z-index:501"
    max-width="450"
    overlay-opacity="0.5"
  >
    <v-card elevation="10">
      <v-form
        ref="EVForm"
        v-model="valid"
        lazy-validation
      >
        <v-card-title class="text-h5">
          {{ $t('new_connections.ev_conn_params') }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="values.ph"
                  :items="phItems"
                  :label="$t('new_connections.phase_connection')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="values.ndevs"
                  :rules="VALIDATIONS.ndevs"
                  :label="$t('new_connections.n_evs')"
                  type="number"
                  dense
                  @change="setToNumber('ndevs')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="values.rcp"
                  :rules="VALIDATIONS.rcp"
                  :label="$t('dt.ev_nominal_power')"
                  type="number"
                  suffix="kW"
                  dense
                  @change="setToNumber('rcp')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="values.c"
                  :rules="VALIDATIONS.c"
                  :label="$t('dt.ev_battery_capacity') "
                  type="number"
                  suffix="kWh"
                  dense
                  @change="setToNumber('c')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="values.pf"
                  :rules="VALIDATIONS.pf"
                  :label="$t('dt.ev_power_factor')"
                  type="number"
                  dense
                  @change="setToNumber('pf')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="values.kmd"
                  :rules="VALIDATIONS.kmd"
                  :label="$t('dt.ev_daily_distance')"
                  type="number"
                  suffix="Km"
                  @change="setToNumber('kmd')"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="values.stdkmd"
                  :rules="VALIDATIONS.stdkmd"
                  :label="$t('new_connections.std')"
                  type="number"
                  suffix="%"
                  dense
                  @change="setToNumber('stdkmd')"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-label style="font-size: 12px">
                  {{ $t('new_connections.ev_charging_time') }})
                </v-label>
              </v-col>
              <v-col cols="12">
                <v-row
                  class="charging-time"
                  dense
                >
                  <v-col cols="5">
                    <v-select
                      v-model="values.start_min"
                      :items="timeRange()"
                      suffix="hour"
                      return-value
                      single-line
                    />
                  </v-col>
                  <v-col cols="2">
                    –
                  </v-col>
                  <v-col cols="5">
                    <v-select
                      v-model="values.start_max"
                      :items="timeRange()"
                      suffix="hour"
                      return-value
                      single-line
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#f4c020"
            text
            @click="closeForm"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            v-if="payload.edit"
            :disabled="!valid"
            color="#f4c020"
            text
            @click="editDevice"
          >
            {{ $t('edit') }}
          </v-btn>
          <v-btn
            v-if="payload.edit"
            color="#f4c020"
            text
            :disabled="!valid"
            @click="deleteDevice"
          >
            {{ $t('delete') }}
          </v-btn>
          <v-btn
            v-else
            :disabled="!valid"
            data-cy="dt-add-ev"
            color="#f4c020"
            text
            @click="addDevice"
          >
            {{ $t('add') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import digitalTwinForm from '@/mixins/digitalTwinForm'

export default {
  name: 'NewConnectionsEVForm',

  mixins: [digitalTwinForm],

  data () {
    return {
      DEVICE: 'EV',
      range: [0, 23],
      values: {
        ndevs: 1,
        ph: 4,
        rcp: 7.5,
        c: 50,
        kmd: 50,
        stdkmd: 3,
        pf: 1,
        start_min: 0,
        start_max: 23
      },
      timeRange: function () {
        const time = []
        const hours = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
          '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
        ]
        hours.forEach((hour, index) => {
          time.push({
            text: hour,
            value: index
          })
        })
        return time
      }
    }
  },

  watch: {
    range ([min, max]) {
      this.values.start_min = min
      this.values.start_max = max
    }
  }
}
</script>

<style scoped>

.row.charging-time {
  align-items: center;
}

.v-range-input {
  width: 36px;
}

.v-application--is-ltr .v-input__slider .v-input__slot .v-label {
  background: rebeccapurple;
}

::v-deep .v-list-item__content{
    text-align:left !important;
}
</style>
