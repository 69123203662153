<template>
  <VMenu
    absolute
    left
  >
    <template #activator="{ on }">
      <div
        class="version-title"        
        v-on="on"
      >
        {{ guiVersion }}
        <VIcon>
          {{ chevronDown }}
        </VIcon>
      </div>
    </template>
    <VList
      class="version-list"
    >
      <VListItem>
        {{ 'GUI: ' + guiVersion }} 
      </VListItem>
      <VListItem>
        {{ 'Ari/Tatari: ' + aritatariBackVersion   }}
      </VListItem>
      <VListItem>
        {{ 'Tia: ' + tiaBackVersion }}
      </VListItem>
    </VList>
  </VMenu>
</template>

<script>
import { mdiChevronDown } from '@mdi/js'
import { getVersion } from '@/services/version'

export default {
  name: 'CMLanguagePicker',
  data: () => ({
    chevronDown: mdiChevronDown,
    guiVersion: '',
    aritatariBackVersion: '',
    tiaBackVersion:''
  }),
  async mounted () {
    let versionData = await getVersion()
    this.guiVersion = versionData.gui
    this.aritatariBackVersion = versionData.aritatari
    this.tiaBackVersion = versionData.tia
  }
}
</script>

<style lang="scss" scoped>
.version-list .theme--dark.v-list-item {
  color:#F4C020;
  font-size:13px;
}
.version-title, .version-title .v-icon {
  color:#F4C020;
  cursor: pointer;
}
::v-deep .v-list-item__link{
    text-align:left !important;
}
::v-deep .v-list-item{
    text-align:left !important;
}
</style>
